<template>
    <v-dialog persistent fullscreen v-model="showDialog">
        <v-card style="padding-bottom: 200px" max-width="700px" flat class="mt-5 mx-auto">
            <v-card-title class="primary white--text">Mitarbeiter Panel</v-card-title>
            <v-row no-gutters>
                <v-col class="pa-5" cols="6">
                    <v-btn elevation="2" class="mr-auto ml-2 mt-2 black--text" @click="$router.push('/')" large icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-end align-center" cols="6">
                    <v-btn outlined class="ml-auto mr-2 mt-2" @click="$router.push('/dashboard')" color="primary">
                        zum Dashboard
                    </v-btn>
                </v-col>
                <v-col class="px-5" cols="12">
                    <v-card max-width="500" class="options-card mx-auto mt-5" elevation="1">
                        <v-card-title class="font-weight-bold h6 mb-0" style="color: #313131; letter-spacing: 0.2px">
                            <v-icon class="pr-2" color="primary" small>mdi-layers</v-icon>
                            Shortcuts
                        </v-card-title>
                        <v-row no-gutters class="px-3">
                            <v-col cols="3" class="px-1 pb-3 text-center align-center d-flex flex-column">
                                <v-btn
                                    height="45px"
                                    width="45px"
                                    class="elevation-1"
                                    @click="createInteraction"
                                    fab
                                    color="white"
                                >
                                    <v-icon color="primary">mdi-face-agent</v-icon>
                                </v-btn>
                                <span class="my-1" style="color: #313131; line-height: 1.2; font-size: 13px">
                                    Interaktion
                                    <br />
                                    hinzufügen
                                </span>
                            </v-col>
                            <v-col cols="3" class="px-1 pb-3 text-center align-center d-flex flex-column">
                                <v-btn
                                    height="45px"
                                    width="45px"
                                    class="elevation-1"
                                    @click="createAnamnese"
                                    fab
                                    color="white"
                                >
                                    <v-icon color="primary">mdi-alpha-a</v-icon>
                                </v-btn>
                                <span class="my-1" style="color: #313131; line-height: 1.2; font-size: 13px">
                                    Anamnese
                                    <br />
                                    erstellen
                                </span>
                            </v-col>
                            <v-col cols="3" class="px-1 pb-3 text-center align-center d-flex flex-column">
                                <v-btn
                                    height="45px"
                                    width="45px"
                                    class="elevation-1"
                                    @click="createTP"
                                    fab
                                    color="white"
                                >
                                    <v-icon color="primary">mdi-dumbbell</v-icon>
                                </v-btn>
                                <span class="my-1" style="color: #313131; line-height: 1.2; font-size: 13px">
                                    TP
                                    <br />
                                    erstellen
                                </span>
                            </v-col>
                            <v-col cols="3" class="px-1 pb-3 text-center align-center d-flex flex-column">
                                <v-btn
                                    height="45px"
                                    width="45px"
                                    class="elevation-1"
                                    @click="createAccount"
                                    fab
                                    color="white"
                                >
                                    <v-icon color="primary">mdi-account-plus</v-icon>
                                </v-btn>
                                <span class="my-1" style="color: #313131; line-height: 1.2; font-size: 13px">
                                    Account
                                    <br />
                                    erstellen
                                </span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <checkin-pool ref="checkinPool" />
            <customerInteraction @interactionCreated="forceRefetchCheckins" ref="customerInteraction" />
            <anamnese-bogen-create @submitted="showGFRecommendations" ref="anamneseBogenRef"></anamnese-bogen-create>
            <register
                @register="newAccountRegistered()"
                :auto-login="false"
                :requirePassword="false"
                @close="showRegisterDialog = false"
                :active="showRegisterDialog"
            ></register>
        </v-card>
    </v-dialog>
</template> 

<script>
import api from '@/api'
import customerInteraction from '@/components/employee/customerInteraction.vue'
import userService from '@/services/userService'
import checkinPool from './components/checkinPool.vue'
import anamneseBogenCreate from '@/components/anamneseBogenCreate'
import register from '@/views/launchScreen/register.vue'
export default {
    components: { checkinPool, customerInteraction, anamneseBogenCreate, register },
    data() {
        return {
            showRegisterDialog: false,
            showDialog: true,
            showAnamnesebogen: false,
            userService,
        }
    },
    methods: {
        forceRefetchCheckins() {
            this.$refs.checkinPool.fetchCheckinPool(true)
        },
        newAccountRegistered() {
            this.showRegisterDialog = false
            this.$toast.success('Neuer Account erfolgreich erstellt.')
        },
        showGFRecommendations(userId) {
            if (this.userService.hasRight('ab_create')) this.$router.push('/gfrecommendations/' + userId)
        },

        createAccount() {
            this.showRegisterDialog = true
        },
        createTP() {
            this.$router.push('/workouts/create')
        },
        createAnamnese() {
            this.$refs.anamneseBogenRef.setVisible()
        },
        createInteraction() {
            this.$refs.customerInteraction.setVisible()
        },
    },
}
</script>
